@media only screen and (max-width: 600px) {
  .about-container {
    max-width: 75%;
    width: unset !important;
  }
}

.about-body {
  background-color: #00000061;
  border-radius: 1rem;
  padding: 1rem;
}

.about-body > p > a {
  font-family: 'Fredoka One', cursive;
  font-weight: normal;
}

.about-body > p > b {
  font-family: 'Fredoka One', cursive;
  font-weight: normal;
}

.about-body > p:first-of-type {
  margin-top: 0.75rem;
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 2rem 0rem 2rem;
  width: 600px;
}

.about-container > h1 {
  margin-top: 0;
}
