.landing-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  max-width: 75%;
  width: 600px;
}

.landing-container > p {
  margin-top: 0;
}

.logo {
  max-height: 25%;
}

.nav-links {
  display: flex;
}

.nav-links > h2 {
  font-weight: normal;
  padding-left: 2rem;
}

.nav-links > h2:last-of-type {
  padding-right: 2rem;
}

.play-store-button {
  margin: 1rem;
}

.play-store-button > img {
  width: 200px;
}
