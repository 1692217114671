@media only screen and (max-width: 600px) {
  .contact-container {
    max-width: 75%;
    width: unset !important;
  }
}

.contact-body {
  background-color: #00000061;
  border-radius: 1rem;
  padding: 1rem;
}

.contact-body > p > a {
  font-family: 'Fredoka One', cursive;
  font-weight: normal;
}

.contact-body > p:first-of-type {
  margin-top: 0.75rem;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  padding: 0 2rem;
  width: 600px;
}

.contact-subtext {
  padding-bottom: 1rem;
}

.contact-subtext:last-of-type {
  padding-bottom: 0 !important;
}
