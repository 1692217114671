body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #c1a8ec;
}

@media only screen and (max-width: 600px) {
  .about-container {
    max-width: 75%;
    width: unset !important;
  }
}

.about-body {
  background-color: #00000061;
  border-radius: 1rem;
  padding: 1rem;
}

.about-body > p > a {
  font-family: 'Fredoka One', cursive;
  font-weight: normal;
}

.about-body > p > b {
  font-family: 'Fredoka One', cursive;
  font-weight: normal;
}

.about-body > p:first-of-type {
  margin-top: 0.75rem;
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 2rem 0rem 2rem;
  width: 600px;
}

.about-container > h1 {
  margin-top: 0;
}

@media only screen and (max-width: 600px) {
  .contact-container {
    max-width: 75%;
    width: unset !important;
  }
}

.contact-body {
  background-color: #00000061;
  border-radius: 1rem;
  padding: 1rem;
}

.contact-body > p > a {
  font-family: 'Fredoka One', cursive;
  font-weight: normal;
}

.contact-body > p:first-of-type {
  margin-top: 0.75rem;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  padding: 0 2rem;
  width: 600px;
}

.contact-subtext {
  padding-bottom: 1rem;
}

.contact-subtext:last-of-type {
  padding-bottom: 0 !important;
}

.divider {
    border-bottom: 1px solid;
    margin-bottom: 6rem;
    max-width: 75%;
    width: 600px;
}
@media only screen and (max-width: 600px) {
  .donate-container {
    max-width: 75%;
    width: unset !important;
  }
}

.bitcoin-qr {
  height: 128px;
  width: 128px;
}

.donate-body {
  align-items: center;
  background-color: #00000061;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.donate-body > p:first-of-type {
  margin-top: 0.75rem;
}

.donate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  padding: 0 2rem 30vh;
  width: 600px;
}

.long-text {
  word-break: break-all;
}

.landing-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  max-width: 75%;
  width: 600px;
}

.landing-container > p {
  margin-top: 0;
}

.logo {
  max-height: 25%;
}

.nav-links {
  display: flex;
}

.nav-links > h2 {
  font-weight: normal;
  padding-left: 2rem;
}

.nav-links > h2:last-of-type {
  padding-right: 2rem;
}

.play-store-button {
  margin: 1rem;
}

.play-store-button > img {
  width: 200px;
}

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3 {
  font-family: 'Fredoka One', cursive;
}

p {
  font-family: 'Raleway', sans-serif;
}

#root {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 100%;
}

.app-container {
  align-items: center;
  background-color: #191919;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.app-container > h1 {
  font-size: 3rem;
}

.app-container > h2 {
  font-size: 2rem;
}

.copyright {
  color: #606060;
  font-size: 0.5rem;
  padding: 1rem;
}

