@media only screen and (max-width: 600px) {
  .donate-container {
    max-width: 75%;
    width: unset !important;
  }
}

.bitcoin-qr {
  height: 128px;
  width: 128px;
}

.donate-body {
  align-items: center;
  background-color: #00000061;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.donate-body > p:first-of-type {
  margin-top: 0.75rem;
}

.donate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  padding: 0 2rem 30vh;
  width: 600px;
}

.long-text {
  word-break: break-all;
}
