html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3 {
  font-family: 'Fredoka One', cursive;
}

p {
  font-family: 'Raleway', sans-serif;
}

#root {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 100%;
}

.app-container {
  align-items: center;
  background-color: #191919;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.app-container > h1 {
  font-size: 3rem;
}

.app-container > h2 {
  font-size: 2rem;
}

.copyright {
  color: #606060;
  font-size: 0.5rem;
  padding: 1rem;
}
